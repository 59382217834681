<template>
  <div class="order-index">
    <subnav showPrevious currPage="footerAsset"></subnav>
    <v-container class="my-5">
      <div class="order-item white rounded-lg pa-3">
        <contractProgress :data="item" class="mb-4"></contractProgress>
        <div class="d-flex align-start">
          <img :src="`${require(`@/assets/img/icon-logo-${$route.params.depositToken}.svg`)}`" :alt="`${$route.params.depositToken}-${$route.params.interestToken}`" class="mr-2 token-img">
          <div class="flex-grow-1 rem-0">
            <div class="d-flex justify-space-between mb-2">
              <div>
                <span class="lightGrey2--text rem-sm-1">{{ $t('orderId', {id: item.id}) }}</span>
                <div class="font-share-tech font-weight-bold rem-8 mb-1 primary--text">{{ $t('orderInterest', { amount: item.payment_amount }) }}</div>
                <div class="lightGrey2--text mb-1 rem-0 rem-sm-1">{{ $t('orderRate', {value: item.rate, interestToken: $route.params.interestToken.toUpperCase(), depositToken: $route.params.depositToken.toUpperCase()}) }}</div>
                <div class="font-share-tech font-w-4 mb-1">
                  <span class="rem-8 rem-sm-12 mr-1" style="color: #00A77B;">{{ (item.amount || 0).toLocaleString() }} {{ $route.params.depositToken.toUpperCase() }}</span>
                  <span class="rem-3 rem-sm-6 lightGrey3--text">/ {{ ((item.amount * item.rate) || 0).toLocaleString() }} {{ $route.params.interestToken.toUpperCase() }}</span>
                </div>
              </div>

              <div class="asset-btn rounded-lg d-flex flex-column justify-center align-center px-4" :class="item.status && item.payment_amount !== '0' ? 'can-click' : 'disabled'" @click="showDialog('claim')">
                <img :src="`${require(`@/assets/img/icon-polygon-${$route.params.interestToken}-${item.status && item.payment_amount !== '0' ? 'primary' : 'disabled'}.svg`)}`" :alt="item.token" class="mb-1">
                <span class="rem-0" :class="item.status && item.payment_amount !== '0' ? 'lightPrimary2--text' : ''">{{ $t('claimInterest') }}</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 詳細資訊 -->
        <div class="d-flex justify-space-between align-center font-w-4">
          <span class="title">{{ $t('detailedInfo') }}</span>
          <span class="rem-2 lightGrey1--text">{{ $t('profitDescription', {token: $route.params.interestToken.toUpperCase()}) }}</span>
        </div>

        <div class="details-block pa-4 rem-3 mb-4 primary--text">
          <div class="d-flex justify-space-between align-center mb-3">
            <div>{{ $t('depositDate') }}</div> <span>{{ timestampToDate(timeInfo[0] * 1000 * 60 * 60 * 24 || 0) }}</span>
          </div>
          <div class="d-flex justify-space-between align-center mb-3">
            <div>{{ $t('startInterestDate') }}</div> <span>{{ timestampToDate(timeInfo[1] * 1000 * 60 * 60 * 24 || 0) }}</span>
          </div>
        </div>

        <!-- 月存預估費率 -->
        <div class="d-flex justify-space-between align-center font-w-4">
          <span class="title">{{ $t('monthExpectedRate') }}</span>
          <div class="can-click" @click="isShowAllRate = !isShowAllRate">
            <span class="rem-3 lightGrey1--text">{{ $t(isShowAllRate ? 'showLess' : 'showMore') }}</span>
            <v-icon color="lightGrey1" size="18px" class="mx-2" :style="`transform: rotate(${isShowAllRate ? '180deg' : '0'})`">mdi-chevron-down</v-icon>
          </div>
        </div>

        <div class="month-rate-block pa-4 rem-3 mb-5">
          <div
            class="d-flex justify-space-between align-center mb-3"
            :class="item.hasClaim >= 30 ? 'complete-text' :
                    item.totalTime >= 30 ? 'lightPrimary2--text' : 'lightGrey1--text'"
          >
            <div>{{ $t('firstMonth') }}</div> <span>{{ (rates[0] * 100).toFixed(2) }} %   {{ $route.params.interestToken.toUpperCase() }}</span>
          </div>
          <div
            class="d-flex justify-space-between align-center mb-3"
            :class="item.hasClaim >= 30 * (i + 2) ? 'complete-text' :
                    item.totalTime >= 30 * (i + 2) ? 'lightPrimary2--text' : 'lightGrey1--text'"
            v-for="(rate, i) in isShowAllRate ? rates.slice(1) : rates.slice(1, 4)"
            :key="i"
          >
            <div>{{ $t('nthMonth', {month: i + 2}) }}</div> <span>{{ (rates[i+1] * 100).toFixed(2) }} %   {{ $route.params.interestToken.toUpperCase() }}</span>
          </div>
        </div>

        <btnPill
          class="mb-2"
          btnText="redeemDepositToken"
          :bgColor="now - item.start_day > (60 * 60 * 24) ? '#FF5B45' : 'primary'"
          :disabled="!item.status"
          @clickBtn="showDialog('redeem')"
        />
        <div class="rem-3 font-w-4 text-center lightPrimary2--text">{{ $t('depositDescription') }}</div>
      </div>
    </v-container>
  </div>
</template>
<script>
import btnPill from '@/components/btn-pill.vue'
import subnav from '@/components/subnav.vue'
import contractProgress from '@/components/progress.vue'
import base from '@/mixin/base'
export default {
  mixins: [base],
  data () {
    return {
      isShowAllRate: false,
      timeInfo: [],
      rates: [],
      item: {}
    }
  },
  components: {
    btnPill,
    subnav,
    contractProgress,
  },
  methods: {
    async getOrderDetails() {
      let result = await this.$store.dispatch('getOrderDetails', this.$route.params.orderId)
      if (result.status === 200 && result.data) {
        this.rates = result.data.order_percent
        this.timeInfo = result.data.order_day
      }
    },
    showDialog(type) {
      if (type === 'redeem') {
        if (!this.item.status) return;
      } else {
        if (!this.item.status || this.item.payment_amount === '0') return;
      }
      this.$store.commit('updateDialog', {isShow: true, type: type, data: this.item, currIndex: this.$route.params.currIndex})
    },
  },
  async created() {
    if (this.$route.params.data) {
      this.item = this.$route.params.data
      await this.getOrderDetails()
    } else {
      this.$router.push({
        name: 'Asset',
        params: {
          lang: this.$store.state.locale,
          interestToken: this.$route.params.interestToken,
        }
      })
    }
  }
}
</script>
<style lang="scss">
.order-index {
  @include dai_vuetify_sm_min {
    max-width: 420px;
    margin: 0 auto;
  }
  .order-item {
    img.token-img {
      margin-top: 22px;
    }

    .asset-btn {
      height: 70px;
      background: var(--v-primary-base);
      border: 1px solid var(--v-lightPrimary2-base);
      &.disabled {
        color: #BDBDBD !important;
        background: #878787;
        border-color: #878787 !important;
      }
    }

    .title {
      color: var(--v-primary-base);
      font-size: 15px;
      margin-bottom: 7px;
      @include dai_vuetify_sm {
        font-size: 15px;
      }
    }

    .details-block, .month-rate-block {
      background: rgba(#F0CD8F, .3);
      border-radius: 2px;
      div {
        position: relative;
        div:first-child::before {
          position: relative;
          display: inline-block;
          content: '';
          width: 7.5px;
          height: 7.5px;
          margin-right: 8px;
          background: var(--v-primary-base);
          border-radius: 1px;
          transform: rotate(45deg);
          z-index: 2;
        }
        &:nth-child(n+2)div:first-child::after {
          position: absolute;
          content: '';
          left: 3.3px;
          top: calc(-50% - 8px);
          background: var(--v-lightGrey1-base);
          width: 1px;
          height: 125%;
          z-index: 1;
        }
      }
    }

    .month-rate-block {
      div.complete-text {
        div:first-child::after {
          background: #53A29C !important;
        }
      }
      div.lightPrimary2--text {
        div:first-child::after {
          background: var(--v-lightPrimary2-base) !important;
        }
      }
    }

    .details-block div:nth-last-child(1)div:first-child::after {
      background: var(--v-primary-base);
    }

    .month-rate-block div:nth-child(n+2) div:first-child::before {
      background: var(--v-lightGrey1-base);
    }

    .month-rate-block div.complete-text div:first-child::before {
      background: #53A29C;
    }

    .month-rate-block div.lightPrimary2--text div:first-child::before {
      background: var(--v-lightPrimary2-base);
    }
  }

  .complete-text {
    color: #53A29C;
  }
}
</style>